.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.post-container {
  display: flex;
  flex-direction: column;
}

.post-wrapper {
  background: white;

  border-radius: 1.3125rem;
}

.post {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.post-profile {
  display: flex;
}

.post-profile-img {

}

.post-profile-info {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.post-profile-info--handle {
  font-size: 18px;
  font-weight: 500;
}

.post-body {
  color: black;
  width: 100%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-desc {
  color: black;
  width: 100%;
  margin-top: 1rem;
  line-height: 22px;
  font-weight: 400;
  text-align: left;
  white-space: break-spaces;
  font-size: 14px;
}

.post-hashtag {
  color: blue;
  margin-top: 1rem;
  font-weight: 600;
  font-style: italic;
  font-size: smaller;
}

.post-img-container {
  margin-top: 1rem;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2%;
}

.post-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.post-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  text-align: left;
  padding-top: 1rem;
  color: #667085;
  font-size: 12px;
  word-wrap: break-word;
}

.profiles-container {
  width: var(--posts-width);
  gap: 2rem;
  display: flex;
  flex-direction: column;
}

.profiles-container {
  margin: 0 auto;
  /*transform: rotateX(180deg);*/
}

.feed-wrapper {
  margin-top: 3rem;
  background: #F2F4F7;
}

:root {
  --posts-width: 42rem;
  --posts-border-color: lightgray;
}

.strategy-input-wrapper {
  width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;

}

.strategy-input {
  width: 400px;
  background-color: #fff;
  border-radius: 12px;
  padding-left: 140px;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px, rgba(0, 0, 0, .14) 0 6px 10px 0, rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  height: 40px;
}

.strategy-btn-wrapper {
  width: 100%;
  height: 3rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 14px;
  background: white;
}

.strategy-btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  color: rgba(21, 18, 24, 0.80);
  font-weight: 500;
  background: rgba(21, 18, 24, 0.05);
  border-radius: 1.5rem;
  border-width: 0px;

  cursor: pointer;
  max-width: 100%;
}

.active-strategy-btn {
  /* color: white; */
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  color: rgba(21, 18, 24, 0.80);
  font-weight: 600;
  background: #BDB7FE;
  border-radius: 1.5rem;
  border-width: 0px;
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.3;
  z-index: 100000;
  cursor: not-allowed;
}

.disabled {
  cursor: not-allowed;
  background-color: lightgrey;
}

.header-text {
  color: #151218;
  font-size: 64px;
  font-family: Beretta Sans;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 96px;
  word-wrap: break-word
}

.subheader-text {
  color: rgba(21, 18, 24, 0.80);
  font-size: 24px;
  font-family: DM Sans;
  font-weight: 400;
  line-height: 36px;
  word-wrap: break-word;
}

.btn-subheader-description {
  display: flex;
  height: 3rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 999px;
  overflow: hidden;
  border: 2px #151218 solid;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.btn-subheader-description--inner {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  word-wrap: break-word;
}

.header-titles {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 5%
}

.about-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  background: var(--main-colors-purple, #BDB7FE);
  padding-bottom: 1.5rem;
}

.about-desc {
  text-align: center;
  color: rgba(21, 18, 24, 0.8);
  font-size: 20px;
  font-family: "DM Sans";
  font-weight: 400;
  line-height: 30px;
  overflow-wrap: break-word;
}

.btn-about-description {
  display: flex;
  color: rgba(21, 18, 24, 0.8);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 999px;
  overflow: hidden;
  border: 2px #151218 solid;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.btn-about-about--inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  word-wrap: break-word;
  font-family: DM Sans;
}

.about-card-wrapper {
  max-width: 1280px;
  margin-left: 2rem;
  margin-right: 2rem;
  display: flex;
  gap: 2rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 2rem;
}

.card-about-desc {
  width: 24rem;
  /* height: 24rem; */
  padding-bottom: 2rem;
  background: white;
  border-radius: 1rem;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  gap: 1.5rem;
  display: inline-flex;

}

.card-about-desc--header {
  align-self: stretch;
  height: 1rem;
  background-color: #A0E7B7;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-about-desc--title {
  min-height: 6rem;
  color: var(--Grey-400, #98A2B3);
  font-family: Beretta Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 44px */
  text-transform: uppercase;
  text-align: left;
  margin-left: 2rem;
  margin-right: 2rem;
}

.card-about-desc--desc {
  color: var(--k-3-l-grey-500-x, #667085);
  /* brand/p4 */
  font-family: DM Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 25.2px */
  text-align: left;

  margin-left: 2rem;
  margin-right: 2rem;

}

@media (max-width: 768px) {
  .header-titles {

  }
  .profiles-container {
    width: 100%;
  }
  .container {
    overflow-y: hidden;
  }

  .strategy-btn-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .post-wrapper {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .post {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

@media (max-width: 1536px) {
  .about-card-wrapper {
    justify-content: center;
  }
}