/* latin-ext */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6z9mXgjU0.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K6z9mXg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6z9mXgjU0.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
/* latin */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K6z9mXg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6z9mXgjU0.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 600;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K6z9mXg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu6-K6z9mXgjU0.woff2) format('woff2');
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 700;
	src: url(https://fonts.gstatic.com/s/dmsans/v14/rP2Yp2ywxg089UriI5-g4vlH9VoD8Cmcqbu0-K6z9mXg.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Beretta Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Beretta Sans'), url(./fonts//BerettaSans/BerettaSansTrial-Bold.otf) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Beretta Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Beretta Sans'), url(./fonts//BerettaSans/BerettaSansTrial-Regular.otf) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Beretta Sans';
	font-style: normal;
	font-weight: 300;
	src: local('Beretta Sans'), url(./fonts//BerettaSans/BerettaSansTrial-Light.otf) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

body {
	margin: 0;
	font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.ranking-a {
	position: absolute;
	top: 10px;
	right: 15px;
	text-decoration: underline;
	font-size: 12px;
}

/* Reset */

*,
*::before,
*::after {
	box-sizing: border-box;
}
* {
	margin: 0;
}

html,
body {
	height: 100%;
}

body {
	line-height: 1.2;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeSpeed;
}

picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select {
	font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

h1 {
	font-size: 8vw;
}

h6 {
	font-size: 2vw;
}

@media screen and (max-width: 400px) {
	h1 {
		font-size: 32px;
	}
}

@media screen and (max-width: 600px) {
	h6 {
		font-size: 12px;
	}
}

@media screen and (min-width: 800px) {
	h1 {
		font-size: 64px;
	}
	h6 {
		font-size: 16px;
	}
}

#root {
	isolation: isolate;
}

/* make sure 1rem == 16px */
html {
	font-size: 16px;
}

/* Globals */

:root {
	--c-black: #000411;
	--c-white: #ffffff;
	--c-dark-purple: #160c28;
	--c-honeydew: #e1efe6;
	--c-main: var(--c-dark-purple);

	--f-body: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
		Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
		Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
	--f-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
		'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
		'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

body {
	font-family: var(--f-body);
}

.mono {
	font-family: var(--f-mono);
}

.visuallyhidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

.container {
	/* width: 95%; */
	margin: 0 auto;
	background: #F2F4F7;
}

.links {
	text-align: end;
	font-size: 18px;
	font-weight: 500;
}

.links a {
	text-decoration: none;
}

.current-link {
	pointer-events: none; /* Disable mouse events */
	opacity: 0.6; /* Visually indicate disabled state */
}

.current-link::before {
	content: "▶ ";
}

a {
	text-decoration: none;
	color: rgba(0, 0, 255, 0.8);
}
a:visited {
	color: rgb(128, 128, 255);
}

/* CSS */
.btn {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 12px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: 15px;
  height: 40px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.btn-search {
	background: #dae7ef;
}

input {
	appearance: none;
	border: none;
	box-shadow: none;
	padding: 0.5em 0.5em;
	display: inline-block;
	cursor: pointer;
	border-radius: 0.25rem;
	font-weight: 600;
}

button {
	cursor: pointer;
}

.loader {
	width: 48px;
	height: 48px;
	border-radius: 50%;
	position: fixed;
	top: 24px;
	right: 48px;
	animation: rotate 1s linear infinite;
}
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 5px solid #fff;
	animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes prixClipFix {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(
			50% 50%,
			0 0,
			100% 0,
			100% 100%,
			100% 100%,
			100% 100%
		);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}

/* Specific */

main {
	margin-top: 0;
}

header {
	/* color: #151218CC; */
	text-align: center;
	/* padding: 1%; */
	background-color: #A0E7B7;
	/* background-image: linear-gradient(315deg, #2a2a72 0%, #009ffd 74%); */
}

.header-links {
	width: 100%;
	text-align: right;
}

a.header-link {
	text-align: end;
	font-size: 18px;
	font-weight: 500;
	text-decoration: none;
	color: white;
	padding: 0 0.5em;
	text-decoration: none;
}

.logos {
	display: flex;
	justify-content: center;
	align-items: center;
}

.logos-flex {
	padding-top: 5rem;
	display: flex;
	align-items: flex-end;
	gap: 1rem;
}

.line {
    width: 2px;
	height: 66px; /* adjust this value as needed */

     /* adjust this value as needed */
    background-color: #151218;
    align-self: flex-end;
}

.logo {
	margin-bottom: 20px;
}

.logo-lens {
	margin-top: -15px;
	margin-left: 10px;

}

.title {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	font-size: 1.5rem;
	font-weight: 100;
	margin-bottom: 4rem;
	text-align: center;
	align-items: center;
}

.title .description {
	font-weight: 500px;
	font-family: 'ui-monospace';
}

.strategies {
	margin-bottom: 40px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 20px;
}

.strategies .btn:not(.active) {
	background-color: var(--c-honeydew);
	color: black;
	white-space: nowrap;
}

header form {
	padding: 20px 0;
}

.search {
	display: flex;
	justify-content: center;
}

.search input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	width: 415px;
}

.suggestions {
	max-width: 400px;
	margin: auto;
	margin-top: -25px;

	border-radius: 2px;

	background-color: white;
	width: 400px;
	color: black;
	text-align: left;
	z-index: 10000;
	border-top: 1px solid gray;
}

.suggestions-li {
	display: flex;
	padding: 3px 10px;
	cursor: pointer;
	min-height: 25px;
}

.suggestions-li:hover {
	background-color: var(--c-honeydew);
}

.search .btn {
	border-radius: 0;
	color: black;
	white-space: nowrap;
}

.search .btn:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	white-space: nowrap;
}

.strategies details {
	margin-left: auto;
}

.profiles-grid {
	display: grid;
	grid-template-columns: 4fr 2fr 10fr 5fr 5fr 8fr 5fr 10fr;
	margin: 20px 0;

	min-width: 800px;
}

.scroll {
	overflow-x: scroll;
	/*transform: rotateX(180deg);*/
}

.scroll::-webkit-scrollbar {
	width: 10px;
	visibility: visible;
}






.profiles-grid>div {
	display: contents;
}

.profiles-grid span,
.profiles-grid strong {
	padding: 8px 0;

}

.profiles-grid span:first-child {
	/*padding-left: 0.5rem;*/
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.profiles-grid span:last-child {
	border-top-right-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.profiles-grid .active-row span {
	font-weight: bold;
	background-color: rgba(255, 255, 255, 0.2);
}

.pagination {
	border-radius: 0.25rem;
	overflow: hidden;
	display: inline-flex;
	align-items: stretch;
}

.pagination button,
.pagination span {
	appearance: none;
	border: none;
	box-shadow: none;
	padding: 0.5rem 0.75rem;
	background-color: var(--c-naples-yellow);
	line-height: 1.25;
	color: var(--c-black);
	transition: opacity 0.3s;
}

.pagination button:disabled {
	opacity: 0.8;
	cursor: not-allowed;
}

.pagination span {
	user-select: none;
	-webkit-user-select: none;
	cursor: default;
}

/* Tooltip container */
.tooltip {
	position: relative;
	display: inline-block;
	border-bottom: 1px dotted black;
	/* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	background-color: rgba(0, 0, 0, 0.8);
	color: var(--c-white);
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	font-weight: normal;

	width: 150%;
	bottom: 110%;
	left: 0%;
	margin-left: -60px;
	/* Use half of the width (120/2 = 60), to center the tooltip */

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}

.twitter {
	font-weight: bold;
	position: relative;
	height: 40px;
	border: none;
	padding: 1px 12px 1px 12px;
	background-color: #1d9bf0;
	color: #fff;
	border-radius: 4px;
	font-weight: 500;
	cursor: pointer;
}

.twitter i {
	position: relative;
	top: 2px;
	display: inline-block;
	width: 14px;
	height: 14px;
	background: transparent 0 0 no-repeat;
	background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2072%2072%22%3E%3Cpath%20fill%3D%22none%22%20d%3D%22M0%200h72v72H0z%22%2F%3E%3Cpath%20class%3D%22icon%22%20fill%3D%22%23fff%22%20d%3D%22M68.812%2015.14c-2.348%201.04-4.87%201.744-7.52%202.06%202.704-1.62%204.78-4.186%205.757-7.243-2.53%201.5-5.33%202.592-8.314%203.176C56.35%2010.59%2052.948%209%2049.182%209c-7.23%200-13.092%205.86-13.092%2013.093%200%201.026.118%202.02.338%202.98C25.543%2024.527%2015.9%2019.318%209.44%2011.396c-1.125%201.936-1.77%204.184-1.77%206.58%200%204.543%202.312%208.552%205.824%2010.9-2.146-.07-4.165-.658-5.93-1.64-.002.056-.002.11-.002.163%200%206.345%204.513%2011.638%2010.504%2012.84-1.1.298-2.256.457-3.45.457-.845%200-1.666-.078-2.464-.23%201.667%205.2%206.5%208.985%2012.23%209.09-4.482%203.51-10.13%205.605-16.26%205.605-1.055%200-2.096-.06-3.122-.184%205.794%203.717%2012.676%205.882%2020.067%205.882%2024.083%200%2037.25-19.95%2037.25-37.25%200-.565-.013-1.133-.038-1.693%202.558-1.847%204.778-4.15%206.532-6.774z%22%2F%3E%3C%2Fsvg%3E);
}

.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip {
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
	padding: 8px;
	width: 120px;
	background-color: black;
	color: white;
	border-radius: 6px;
	font-size: 14px;
	text-align: center;
}

.scroll-container {
	overflow-x: auto;
	white-space: nowrap;
	scrollbar-width: thin;
	/* Firefox */
	scrollbar-color: #f0f0f0 #d3d3d3;
	/* Firefox */
}

.scroll-container::-webkit-scrollbar {
	height: 8px;
}

.scroll-container::-webkit-scrollbar-track {
	background-color: #d3d3d3;
	border-radius: 8px;
}

.scroll-container::-webkit-scrollbar-thumb {
	background-color: #f0f0f0;
	border-radius: 8px;
}

.scroll-container>* {
	display: inline-block;
	width: 300px;
	height: 200px;
	margin-right: 20px;
	background-color: #f0f0f0;
}

